.guidde-extension input:active {
    background: transparent;
    box-shadow: none;
}

.guidde-extension p > b {
    font-weight: bold;
}
.guidde-extension button {
    min-width: auto;
}

.guidde-extension img {
    vertical-align: middle;
}

.guidde-extension * {
    box-sizing: border-box;
}

.guidde-extension span,
.guidde-extension p {
    margin-bottom: initial;
}

/* Needed to prevent event bubbling on page iframes */
.GUIDDE-DisablePointers.GUIDDE-DisablePointers.GUIDDE-DisablePointers {
    pointer-events: none;
}
